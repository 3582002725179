var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: {
                  title: "LBL0000276",
                  noMarginPadding: true,
                  height: "405px",
                },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c("c-pick-file", {
                      attrs: {
                        editable: _vm.editable && _vm.isWriting,
                        outlined: "",
                        dense: true,
                      },
                      on: { getImageData: _vm.getImageData },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend",
                          fn: function () {
                            return [
                              _c("q-icon", { attrs: { name: "attach_file" } }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.file,
                        callback: function ($$v) {
                          _vm.file = $$v
                        },
                        expression: "file",
                      },
                    }),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [_c("q-img", { attrs: { src: _vm.supWork.sketchSrc } })],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8" },
          [
            _c("c-table", {
              attrs: {
                title: "LBL0000232",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.supWork.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }